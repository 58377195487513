import axios from "axios";
import groupBy from "lodash/groupBy";

export default {
  async getRoomControl({ commit, rootState }) {
    commit("FETCHING_GET_ROOMCONTROL");
    const { propertyId, authResult } = rootState.session;

    try {
      let request = await axios.get(
        `/api/public/v2/property/${propertyId}/oneapp/roomcontrol`
      );

      let defaultAssignedCommands = [];
      let assignedCommands = [];
      let allAssignedCommands = [];
      let targetId = "";

      /* Get default controls */
      if (request.data.assignment[0].default) {
        defaultAssignedCommands =
          request.data.assignment[0].assignedCommandNames;
      }

      /* Get room-based controls */
      request.data.assignment.forEach((item) => {
        if (authResult.checkin[0].room === item.room) {
          assignedCommands = item.assignedCommandNames;
          ({ targetId = "" } = item);
        }
      });

      allAssignedCommands = [...defaultAssignedCommands, ...assignedCommands];

      const items = [];
      request.data.command.forEach((item) => {
        if (allAssignedCommands.includes(item.name)) {
          items.push(item);
        }
      });

      let groupedItems = groupBy(items, "type");
      commit("SUCCESS_GET_GROUP", Object.keys(groupedItems));
      commit("SUCCESS_GET_ROOMCONTROL", { items });
      commit("SET_TARGET_ID", targetId);
      commit("SET_STATE", request.data.state);
    } catch (error) {
      commit("FAILURE_GET_ROOMCONTROL");
      console.error(error);
    }
  },
  async setRoomControl({ rootState, commit }, { item, state = {} }) {
    commit("FETCHING_SET_ROOMCONTROL");
    const { propertyId } = rootState.session;
    const room = rootState.session.authResult.checkin[0].room;
    const { protocol } = rootState.session.settings.roomControl;

    try {
      await axios.post(
        `/api/public/v2/property/${propertyId}/oneapp/roomcontrol`,
        { setting: { type: protocol }, room, command: item, state }
      );
      commit("SUCCESS_SET_ROOMCONTROL");
    } catch (error) {
      commit("FAILURE_SET_ROOMCONTROL");
      console.error(error);
    }
  },
};
