import Vue from "vue";

export default {
  FETCHING_GET_ROOMCONTROL(state) {
    state.statusGet = "fetching";
  },
  SUCCESS_GET_ROOMCONTROL(state, { items }) {
    state.statusGet = "success";
    state.items = items;
  },
  FAILURE_GET_ROOMCONTROL(state) {
    state.statusGet = "failure";
  },
  FETCHING_SET_ROOMCONTROL(state) {
    state.statusSet = "fetching";
  },
  SUCCESS_SET_ROOMCONTROL(state) {
    state.statusSet = "success";
  },
  FAILURE_SET_ROOMCONTROL(state) {
    state.statusSet = "failure";
  },
  SUCCESS_GET_GROUP(state, value) {
    state.types = value.map((item) => {
      return {
        key: item,
        title: Vue.i18n.translate("roomControl." + item),
      };
    });
  },
  SET_TYPE(state, value) {
    state.selectedType = value;
  },
  SET_TARGET_ID(state, value) {
    state.targetId = value;
  },
  SET_STATE(state, value = {}) {
    state.state = value;
  },
};
