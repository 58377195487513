<template>
  <v-list-item
    v-if="
      authResult.checkin &&
      authResult.checkin[0] &&
      authResult.checkin[0].name &&
      authResult.checkin[0].name.length > 0
    "
  >
    <v-list-item-avatar>
      <checked-in-person-avatar />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>{{ authResult.checkin[0].name }}</v-list-item-title>
      <v-list-item-subtitle>{{
        authResult.checkin[0].room
      }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapState } from "vuex";
import CheckedInPersonAvatar from "@/components/CheckedInPersonAvatar";

export default {
  components: {
    CheckedInPersonAvatar,
  },
  computed: {
    ...mapState({
      authResult: ({ session }) => session.authResult,
    }),
  },
};
</script>
